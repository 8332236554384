import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/InnerHero"
import { Link } from "gatsby"

const NewsLetter = ({ page }) => {
  return (
    <Layout>
      <Seo
        title="Web design & web development newsletter - Vermont Web Development"
        description="A top web design and web development service in Vermont. Vermont web design and web development company in Vermont builds high-performing, safe, SEO friendly, and easy to add or edit your content with Content Management Systems (CMS). I write about website performance every month, please subscribe so you won't miss an awesome post from me."
      />
      <main>
        <section id="newsletter">
          <div className="section u-padding-bottom">
            {!page && (
              <>
                <InnerHero>
                  <h1 className="heading__main-title heading__main-title--black">
                    Join My Newsletter
                  </h1>
                  <h2 className="heading__main-title--sub u-no-effect">
                    By email once a month I will help you give your new business
                    or product a push.
                  </h2>
                </InnerHero>
              </>
            )}
          </div>

          <div className="row u-padding">
            <div className="col-1-of-1">
              <form
                className="newsletter__contact-form u-center"
                name="contact"
                method="post"
                netlify-honeypot="bot-field"
                data-netlify="true"
                action="/success"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />

                <p className="newsletter__contact-form-field">
                  <label for="name" className="newsletter__contact-form--label">
                    <span>First name</span>
                    <input
                      type="text"
                      name="name"
                      required
                      placeholder="First name"
                      className="newsletter__contact-form--form-control"
                    />
                  </label>
                </p>

                <p className="newsletter__contact-form-field">
                  <label for="name" className="newsletter__contact-form--label">
                    <span>Last name</span>
                    <input
                      type="text"
                      name="name"
                      required
                      placeholder="Last name"
                      className="newsletter__contact-form--form-control"
                    />
                  </label>
                </p>

                <p className="newsletter__contact-form-field">
                  <label
                    for="email"
                    className="newsletter__contact-form--label"
                  >
                    <span>Your email</span>
                    <input
                      type="email"
                      name="email"
                      required
                      placeholder="Your email"
                      className="newsletter__contact-form--form-control"
                    />
                  </label>
                </p>

                <button
                  type="submit"
                  className=" btn btn--primary u-margin-top btn--animated newsletter__contact-form--form-control submit-btn"
                >
                  subscribe
                </button>
                <Link to="/" className="u-margin-top-small">
                  <p id="text">
                    No, thanks. I don't need to improve the website for my
                    business.
                  </p>
                </Link>
              </form>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default NewsLetter
